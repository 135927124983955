import { type ToastOptions, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

type ToastType = 'success' | 'error' | 'info' | 'warning'

const defaultOptions: ToastOptions = {
  position: 'bottom-right',
  theme: 'colored',
}

const createToast =
  (type: ToastType) => (message: string, options?: ToastOptions) => {
    switch (type) {
      case 'success':
        toast.success(message, { ...defaultOptions, ...options })
        break
      case 'error':
        toast.error(message, { ...defaultOptions, ...options })
        break
      case 'info':
        toast.info(message, { ...defaultOptions, ...options })
        break
      case 'warning':
        toast.warning(message, { ...defaultOptions, ...options })
        break
    }
  }

const showToast = {
  success: createToast('success'),
  error: createToast('error'),
  info: createToast('info'),
  warning: createToast('warning'),
}

export default showToast
